/**
 * @module utils
 */
/**
 * Mapping error correction level to an ordinal number
 */
enum ECL {
    LOW = 0, MEDIUM = 1, QUARTILE = 2, HIGH = 3
}

/**
 * Data encoding mode data \
 * *indicator*: Mode indicator \
 * *charCount*: Character count indicator
 *
 * @category Constant
 */
const MODE = {
    NUMERIC: {
        indicator: 0x1,
        charCount: [ 10, 12, 14 ]
    },
    ALPHANUMERIC: {
        indicator: 0x2,
        charCount: [ 9, 11, 13 ]
    },
    BYTE: {
        indicator: 0x4,
        charCount: [ 8, 16, 16 ]
    },
    KANJI: {
        indicator: 0x8,
        charCount: [ 8, 10, 12 ]
    }
};

/**
 * Numeric table
 *
 * @category Constant
 */
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const NUMERIC: string = "0123456789";

/**
 * Alphanumeric table
 *
 * @category Constant
 */
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const ALPHANUMERIC: string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ $%*+-./:";

/**
 * Shift-JIS boundary codepoints
 *
 * @category Constant
 */
const KANJI = {
    factor: 0xC0,
    A: 0x8140,
    B: 0x9FFC,
    C: 0xE040,
    D: 0xEBBF,
    E: 0xC140
};

/**
 * Fixed capacities for each version and error correction level
 *
 * @category Constant
 */
const CAPACITY: Array<Array<number>> = [
    [ -1, -1, -1, -1 ], [ 19, 16, 13, 9 ], [ 34, 28, 22, 16 ], [ 55, 44, 34, 26 ],
    [ 80, 64, 48, 36 ], [ 108, 86, 62, 46 ], [ 136, 108, 76, 60 ], [ 156, 124, 88, 66 ],
    [ 194, 154, 110, 86 ], [ 232, 182, 132, 100 ], [ 274, 216, 154, 122 ], [ 324, 254, 180, 140 ],
    [ 370, 290, 206, 158 ], [ 428, 334, 244, 180 ], [ 461, 365, 261, 197 ],
    [ 523, 415, 295, 223 ], [ 589, 453, 325, 253 ], [ 647, 507, 367, 283 ],
    [ 721, 563, 397, 313 ], [ 795, 627, 445, 341 ], [ 861, 669, 485, 385 ],
    [ 932, 714, 512, 406 ], [ 1006, 782, 568, 442 ], [ 1094, 860, 614, 464 ],
    [ 1174, 914, 664, 514 ], [ 1276, 1000, 718, 538 ], [ 1370, 1062, 754, 596 ],
    [ 1468, 1128, 808, 628 ], [ 1531, 1193, 871, 661 ], [ 1631, 1267, 911, 701 ],
    [ 1735, 1373, 985, 745 ], [ 1843, 1455, 1033, 793 ], [ 1955, 1541, 1115, 845 ],
    [ 2071, 1631, 1171, 901 ], [ 2191, 1725, 1231, 961 ], [ 2306, 1812, 1286, 986 ],
    [ 2434, 1914, 1354, 1054 ], [ 2566, 1992, 1426, 1096 ], [ 2702, 2102, 1502, 1142 ],
    [ 2812, 2216, 1582, 1222 ], [ 2956, 2334, 1666, 1276 ]
];

/**
 * The number of error correction codewords per block
 * according to error correction level and version
 *
 * @category Constant
 */
const EC_CODEWORD_PER_BLOCK: Array<Array<number>> = [
    [ -1,  7, 10, 15, 20, 26, 18, 20, 24, 30, 18, 20, 24, 26, 30, 22, 24,
        28, 30, 28, 28, 28, 28, 30, 30, 26, 28, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30 ],
    [ -1, 10, 16, 26, 18, 24, 16, 18, 22, 22, 26, 30, 22, 22, 24, 24, 28,
        28, 26, 26, 26, 26, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28 ],
    [ -1, 13, 22, 18, 26, 18, 24, 18, 22, 20, 24, 28, 26, 24, 20, 30, 24,
        28, 28, 26, 30, 28, 30, 30, 30, 30, 28, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30 ],
    [ -1, 17, 28, 22, 16, 22, 28, 26, 26, 24, 28, 24, 28, 22, 24, 24, 30,
        28, 28, 26, 28, 30, 24, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30 ],
];

/**
 * The number of error correction blocks required
 * according to error correction level and version
 *
 * @category Constant
 */
const NUM_EC_BLOCK: Array<Array<number>> = [
    [ -1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 4,  4,  4,  4,  4,  6,  6,  6,  6,  7,
        8,  8,  9,  9, 10, 12, 12, 12, 13, 14, 15, 16, 17, 18, 19, 19, 20, 21, 22, 24, 25 ],
    [ -1, 1, 1, 1, 2, 2, 4, 4, 4, 5, 5,  5,  8,  9,  9, 10, 10, 11, 13, 14,
        16, 17, 17, 18, 20, 21, 23, 25, 26, 28, 29, 31, 33, 35, 37, 38, 40, 43, 45, 47, 49 ],
    [ -1, 1, 1, 2, 2, 4, 4, 6, 6, 8, 8,  8, 10, 12, 16, 12, 17, 16, 18, 21,
        20, 23, 23, 25, 27, 29, 34, 34, 35, 38, 40, 43, 45, 48, 51, 53, 56, 59, 62, 65, 68 ],
    [ -1, 1, 1, 2, 4, 4, 4, 5, 6, 8, 8, 11, 11, 16, 16, 18, 16, 19, 21, 25,
        25, 25, 34, 30, 32, 35, 37, 40, 42, 45, 48, 51, 54, 57, 60, 63, 66, 70, 74, 77, 81 ],
];

/**
 * Fixed positions for alignment modules
 *
 * @category Constant
 */
const ALIGNMENT_POSITION: Array<Array<number>> = [
    [ -1 ], [ -1 ], [ 6, 18 ], [ 6, 22 ], [ 6, 26 ], [ 6, 30 ], [ 6, 34 ],
    [ 6, 22, 38 ], [ 6, 24, 42 ], [ 6, 26, 46 ], [ 6, 28, 50 ], [ 6, 30, 54 ],
    [ 6, 32, 58 ], [ 6, 34, 62 ], [ 6, 26, 46, 66 ], [ 6, 26, 48, 70 ], [ 6, 26, 50, 74 ],
    [ 6, 30, 54, 78 ], [ 6, 30, 56, 82 ], [ 6, 30, 58, 86 ], [ 6, 34, 62, 90 ],
    [ 6, 28, 50, 72, 94 ], [ 6, 26, 50, 74, 98 ], [ 6, 30, 54, 78, 102 ],
    [ 6, 28, 54, 80, 106 ], [ 6, 32, 58, 84, 110 ], [ 6, 30, 58, 86, 114 ],
    [ 6, 34, 62, 90, 118 ], [ 6, 26, 50, 74, 98, 122 ], [ 6, 30, 54, 78, 102, 126 ],
    [ 6, 26, 52, 78, 104, 130 ], [ 6, 30, 56, 82, 108, 134 ], [ 6, 34, 60, 86, 112, 138 ],
    [ 6, 30, 58, 86, 114, 142 ], [ 6, 34, 62, 90, 118, 146 ],
    [ 6, 30, 54, 78, 102, 126, 150 ], [ 6, 24, 50, 76, 102, 128, 154 ],
    [ 6, 28, 54, 80, 106, 132, 158 ], [ 6, 32, 58, 84, 110, 136, 162 ],
    [ 6, 26, 54, 82, 110, 138, 166 ], [ 6, 30, 58, 86, 114, 142, 170 ]
];

/**
 * Fixed format information bits
 *
 * @category Constant
 */
const FORMAT_INFO: Array<Array<number>> = [
    [ 0b111011111000100, 0b111001011110011, 0b111110110101010, 0b111100010011101,
        0b110011000101111, 0b110001100011000, 0b110110001000001, 0b110100101110110 ],
    [ 0b101010000010010, 0b101000100100101, 0b101111001111100, 0b101101101001011,
        0b100010111111001, 0b100000011001110, 0b100111110010111, 0b100101010100000 ],
    [ 0b11010101011111, 0b11000001101000, 0b11111100110001, 0b11101000000110,
        0b10010010110100, 0b10000110000011, 0b10111011011010, 0b10101111101101 ],
    [ 0b1011010001001, 0b1001110111110, 0b1110011100111, 0b1100111010000,
        0b11101100010, 0b1001010101, 0b110100001100, 0b100000111011 ]
];

/**
 * Fixed version bits, starts from version 7
 *
 * @category Constant
 */
const VERSION_INFO: Array<number> = [
    0b111110010010100, 0b1000010110111100, 0b1001101010011001, 0b1010010011010011,
    0b1011101111110110, 0b1100011101100010, 0b1101100001000111, 0b1110011000001101,
    0b1111100100101000, 0b10000101101111000, 0b10001010001011101, 0b10010101000010111,
    0b10011010100110010, 0b10100100110100110, 0b10101011010000011, 0b10110100011001001,
    0b10111011111101100, 0b11000111011000100, 0b11001000111100001, 0b11010111110101011,
    0b11011000010001110, 0b11100110000011010, 0b11101001100111111, 0b11110110101110101,
    0b11111001001010000, 0b100000100111010101, 0b100001011011110000, 0b100010100010111010,
    0b100011011110011111, 0b100100101100001011, 0b100101010000101110, 0b100110101001100100,
    0b100111010101000001, 0b101000110001101001
];

/**
 * Penalty factors for each condition
 *
 * @category Constant
 */
const PENALTIES = {
    S1: 3,
    S2: 3,
    S3: 40,
    S4: 10
};

export {
    ECL,
    MODE,
    NUMERIC,
    ALPHANUMERIC,
    KANJI,
    CAPACITY,
    ALIGNMENT_POSITION,
    EC_CODEWORD_PER_BLOCK,
    NUM_EC_BLOCK,
    FORMAT_INFO,
    VERSION_INFO,
    PENALTIES
};
